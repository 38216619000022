/* @license
 * MyFonts Webfont Build ID 2691808, 2013-11-19T16:06:51-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gibson Light by Canada Type
 * URL: http://www.myfonts.com/fonts/canadatype/gibson/light/

 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2691808
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2011 Rod McDonald. Published by Canada Type. All rights reserved.
 *
 * Â© 2013 MyFonts Inc
*/
@font-face {
  font-family: 'gibson-light';
  src: url('../fonts/gibson/gibson-light-webfont.woff2') format('woff2'),
    url('../fonts/gibson/gibson-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* @import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800'); */
@import url('//fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700');

/*
GLOBAL COLOR VARIABLES:
border-color: #c8ccce;
Brand Primary: #49c5b1
Brand Dark Blue: #151c23
Pink <code>: #eb3366
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
}

body {
  background-color: #f6f6f6;
  color: #333;
  font-family: 'Roboto', -system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  text-align: left;
}

::selection {
  background-color: #49c5b1;
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

::-moz-selection {
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

/* Headings and Text Styling */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #333;
  font-family: inherit;
  font-weight: bold;
  font-weight: 500;
  line-height: 1.2;
  margin: 3.2rem 0 1.6rem;
}

h1,
.h1 {
  font-size: 2.8rem;
  margin-bottom: 2.4rem;
}

h2,
.h2 {
  font-size: 2.4rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.8rem;
}

h5,
.h5 {
  font-size: 1.6rem;
}

h6,
.h6 {
  font-size: 1.4rem;
}

h1:first-child,
h2:first-child {
  margin-top: 0;
}

h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0.8rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

.lead {
  color: #777;
  font-size: 1.8rem;
  font-weight: 300;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1.6rem;
  font-style: normal;
  line-height: inherit;
}

hr {
  border: 0;
  border-top: 1px solid #e1e1e1;
  box-sizing: content-box;
  height: 0;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  overflow: visible;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

ol,
ul {
  padding-left: 3.2rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.8rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1.6rem;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-style: italic;
  border-left: 4px solid #e1e1e1;
}

b,
strong {
  font-weight: bolder;
}

del {
  text-decoration: line-through;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2893bc;
  text-decoration: none;
  background-color: transparent;
  transition: 0.2s all ease-in-out;
}

a:hover {
  color: #2893bc;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 4px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}

pre,
pre code {
  display: block;
  hyphens: none;
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #c8ccce;
  border-radius: 2px;
  color: #333;
  font-size: 1.3rem;
  line-height: 1.6;
  margin: 0 0 1.6rem;
  overflow: auto;
  padding: 0.8rem 1.6rem;
  white-space: pre;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  background-color: #f9f2f4;
  border-radius: 3px;
  color: #c7254e;
  display: inline-block;
  font-size: 90%;
  padding: 2px 4px;
}

pre code {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
  margin: 0;
  overflow-x: auto;
  padding: 0;
  white-space: pre;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #151c23;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
}

figure {
  margin: 0 0 1.6rem;
}

caption {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

img {
  border-style: none;
  max-width: 100%;
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: 500;
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.8rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 4px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}
