/* START BOOTSTRAP STYLE TRANSFER (need to cleanup) */
.panel {
  margin-bottom: 2.4rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    /*	0 2px 3px rgba(0,0,0,.1), */ 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}
.panel-body {
  padding: 16px;
}
.panel-heading {
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 12px 16px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  color: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}
.panel-footer {
  /*   background-color: #f9f9f9; */
  /*   border-top: 1px solid #ddd; */
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 1.3rem;
  padding: 16px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel
  > .panel-collapse
  > .list-group:first-child
  .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .panel-heading
  + .panel-collapse
  > .list-group
  .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.panel-default {
  /*   border-color: #c8ccce; */
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #c8ccce;
}

.panel-body:before,
.panel-body:after {
  display: table;
  content: ' ';
}

.panel-body:after {
  clear: both;
}
/* END BOOTSTRAP STYLES */

.cj-panel.panel-heading {
  background-color: #ffffff;
}

.cj-panel.panel-heading legend {
  text-transform: uppercase;
  color: #bbb;
  font-weight: 500;
  font-size: 1.6rem;
  border: none;
  padding-top: 0px;
  margin-bottom: 0px;
}

.cj-panel.panel .cj-panel.panel-body:not(:last-child) {
  border-bottom: 1px solid transparent;
  border-color: #c8ccce;
}
.cj-panel-body-padding-wide.panel-body {
  padding: 32px;
}

.cj-panel-body-padding-none.panel-body {
  padding: 0;
}
