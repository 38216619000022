fieldset p {
  position: relative;
  display: block;
  overflow: hidden;
}

p > label {
  float: left;
  padding: 0 0 0 18px;
}

label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
  max-width: 100%;
  margin: 0 0 0.4rem;
}

label span {
  font-size: 1.4rem;
  font-weight: 400;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}

select,
textarea,
input[type='file'],
input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
  background-color: #fff;
  border: 1px solid #c8ccce;
  border-radius: 3px;
  color: #333;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  height: 32px;
  line-height: 1.6;
  margin-bottom: 0.8rem;
  max-width: 100%;
  padding: 2px 8px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

select:focus,
textarea:focus,
input[type='file']:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: #49c5b1;
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}

select.input-error,
textarea.input-error,
input.input-error[type='file'],
input.input-error[type='text'],
input.input-error[type='password'],
input.input-error[type='datetime'],
input.input-error[type='datetime-local'],
input.input-error[type='date'],
input.input-error[type='month'],
input.input-error[type='time'],
input.input-error[type='week'],
input.input-error[type='number'],
input.input-error[type='email'],
input.input-error[type='url'],
input.input-error[type='search'],
input.input-error[type='tel'],
input.input-error[type='color'] {
  border-color: #e1523d;
  box-shadow: 0 0 4px rgba(225, 82, 61, 0.4);
}

input[type='radio'] + label,
input[type='checkbox'] + label {
  font-weight: 400;
  margin: 0 1.6rem 0 0;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 0.6rem 0.8rem 0.8rem 0;
  line-height: normal;
}

input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin dotted 9;
  outline: 4px auto -webkit-focus-ring-color;
  /*   outline-offset: -2px; */
}

input[type='color'] {
  border-radius: 2px;
  padding: 0 2px;
  height: 24px;
  width: 24px;
}

input[type='file'] {
  display: block;
  font-size: 1.2rem;
  height: auto;
  padding: 4px;
}

input[type='range'] {
  display: block;
  padding: 4px;
  width: 100%;
}

optgroup {
  font-weight: bold;
}

output {
  display: block;
  padding-top: 8px;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #333;
}

progress {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

textarea {
  overflow: auto;
  min-height: 64px;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  color: #888;
  cursor: not-allowed;
  background-color: #eee;
  border-color: #e1e1e1;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}

::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}

::-moz-placeholder {
  color: #999;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #999;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #999;
  opacity: 1;
}

::placeholder {
  color: #999;
  opacity: 1;
}
