/*! --- START CJ Bootstrap Overrides --- */

.nav {
  margin-left: 0;
}

.navbar {
  height: 50px;
  margin-bottom: 5px;
}

/*Global styles to solve for other styles being removed*/

.btn {
  display: inline-block;
  padding: 4px 8px 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #6d6e70;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background: #ffffff;
  background: linear-gradient(to bottom, #ffffff 0%, #e9e9e9 50%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 );
  -webkit-filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  border: 1px solid #dbdcde !important;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.05);
}

.dropdown-selected-label {
  border-right: 1px solid #e4e4e4;
  padding-right: 12px;
}

.page-header {
  padding-bottom: 9px;
  margin: 20px 0 20px;
  border-bottom: 1px solid hsl(0, 0%, 93%);
}

.popover {
  padding: 0;
  border: 2px solid #00af66;
  width: auto;
  max-width: 100%;
}

.popover.bottom .arrow,
.popover.bottom .arrow:after {
  border-bottom-color: #00af66;
}

.popover.top .arrow,
.popover.top .arrow:after {
  border-top-color: #00af66;
}

.popover.right .arrow,
.popover.right .arrow:after {
  border-right-color: #00af66;
}

.popover.left .arrow,
.popover.left .arrow:after {
  border-left-color: #00af66;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: ' ';
}

.clearfix:after {
  clear: both;
}

/*! --- END CJ Bootstrap Overrides --- */
