div.vs-react-select__input input {
  margin-bottom: 0px;
  padding: 0px;
}

div.vs-react-select__input input:focus {
  box-shadow: none;
  border: none;
}

div.vs-default-react-select {
  margin-bottom: 0.8rem;
}

div.vs-react-select__control {
  min-height: 32px;
}

div.vs-react-select__control--is-focused,
div.vs-react-select__control--is-focused:hover {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: #49c5b1;
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}

.vs-react-select__value-container div {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
}

div.vs-react-select__value-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

div.vs-react-select__indicator {
  padding: 6px;
}

div.vs-react-select__option {
  padding: 6px 12px;
  word-break: break-all;
}

div.vs-input-error,
div.vs-input-error div.vs-react-select__control {
  border-color: #e1523d;
  box-shadow: 0 0 4px rgba(225, 82, 61, 0.4);
}

div.vs-react-select__control.vs-react-select__control--is-disabled,
div.vs-react-select__control[disabled] {
  color: #888;
  cursor: not-allowed;
  background-color: #eee;
  border-color: #e1e1e1;
}

div.vs-react-select__option--is-focused {
  background-color: #d4f6f1;
}

div.vs-react-select__option:active,
div.vs-react-select__option--is-focused:active {
  background-color: #ace6dc;
}

div.vs-react-select__option.vs-react-select__option--is-selected,
div.vs-react-select__option.vs-react-select__option--is-selected:active {
  background-color: #29c3aa;
}

div.vs-react-select__multi-value {
  background-color: rgba(172, 230, 220, 0.35);
  border-radius: 2px;
  border: 1px solid #29c3aa;
  color: #068e78;
  font-size: 0.9em;
  line-height: 1.4;
  vertical-align: top;
  white-space: wrap;
  padding: 2px 20px 2px 4px;
  position: relative;
  max-width: 200px;
}

div.vs-react-select__multi-value__label {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}

div.vs-react-select__multi-value__remove:hover {
  background-color: #29c3aa;
  color: white;
}

div.vs-react-select__multi-value__remove {
  margin-left: 2px;
  position: absolute;
  right: 2px;
}
