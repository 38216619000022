.vs-list-toolbar {
  border: 1px solid #c8ccce;
  padding: 0 1.6rem 0 1.6rem;
  border-bottom-width: 0;
}

.vs-list-toolbar-title {
  height: 48px;
  line-height: 48px;
  font-size: 1.6rem;
  font-weight: 500;
}

.vs-list-header {
  background-color: #fafafa;
  border: 1px solid #c8ccce;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 0 transparent;
  padding: 0.4rem 0;
  pointer-events: none;
}

.vs-list-header-label {
  font-size: 1.3rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vs-list-rows {
  border: 1px solid #c8ccce;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.vs-list-row {
  padding: 0.8rem 0;
  border-top: 1px dashed #c8ccce;
}

.vs-list-row:first-child {
  border-top-width: 0;
}

.vs-list-row:hover {
  background: #fafafa;
  cursor: hand;
}

.vs-list-header-pull-down {
  margin-top: 0.8rem;
}

.vs-list-cell {
  border-right: 1px solid #c8ccce;
}

.vs-list-cell:last-child {
  border-right-width: 0;
}
