.form-horizontal .form-group-vertical {
  margin-right: 0;
  margin-left: 0;
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  display: table;
  content: ' ';
}

.form-horizontal .form-group:after {
  clear: both;
}

.form-group-buttons {
  text-align: right;
}

.form-group-buttons .form-button {
  margin-left: 10px;
}

.form-label-vertical {
  margin-bottom: 0.3em;
}

.form-label-text-vertical {
  font-size: 1.1em;
}

.form-group-static-text {
  line-height: 32px;
  padding: 1px 6px;
}

.form-group-required-sign {
  padding-left: 0.3em;
  color: #e1523d;
}

.form-group-error-span {
  display: block;
  color: #e1523d;
  top: 6px;
  position: relative;
}

.has-error .control-label {
  color: #e1523d;
}

.has-error .form-control {
  border-color: #e1523d;
}

.form-group-radio-label {
  font-size: 1em;
  cursor: pointer;
  cursor: hand;
}

.cj-form-input.form-group-radio-input {
  min-height: 24px;
  margin-bottom: 0;
}

.has-help-icon {
  display: inline;
  overflow: visible;
  float: left;
}

.help-icon-form-component {
  margin-left: 15px;
}

.clipboard-input {
  color: #9e868e;
  background-color: inherit;
  cursor: default;
}

.clipboard-button {
  padding-top: 6px;
  padding-right: 12px;
}

.clipboard-button:focus {
  outline: 0;
}

.row-destinationUrl .tooltip-inner {
  width: 350px;
  max-width: 350px;
}

.fieldset-title {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

.fieldset-panel {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

fieldset.group {
  margin: 0;
  margin-bottom: 1.25em;
  padding: 0.125em;
}

fieldset.group ul.checkbox {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  list-style: none;
}

fieldset.group ul.checkbox li input {
  margin-right: 0.25em;
}

fieldset.group ul.checkbox li {
  border: 1px transparent solid;
  display: inline-block;
  width: 12em;
}

fieldset.group ul.checkbox li:hover,
fieldset.group ul.checkbox li.focus {
  background-color: lightyellow;
  border: 1px gray solid;
  width: 12em;
}

.vs-validation-error {
  color: #e1523d;
  font-size: 1.3rem;
  margin-top: -4px;
}

.vs-field-help {
  color: #777;
  font-size: 1.3rem;
  margin-top: -4px;
}

.vs-field {
  margin-bottom: 24px;
}

.vs-field-optional {
  color: #777;
  font-size: 1.3rem;
  font-style: italic;
}

.vs-label-font-bold {
  font-weight: 500;
}

.vs-label-font-normal {
  font-weight: normal;
}
