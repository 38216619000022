/* global button styles */

.vs-btn-d {
  background-color: transparent;
  border: 1px solid transparent;
  border-collapse: separate;
  border-radius: 3px;
  box-shadow: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1;
  margin: 8px 4px;
  min-height: 32px;
  min-width: 32px;
  overflow: hidden;
  padding: 8px 16px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  width: auto;
}

.vs-btn-d:focus,
a.vs-btn-d:focus {
  outline: none;
}

/* CJ Button - primary styles*/

.vs-default-btn {
  border: 1px solid #b6b6b6;
  color: #b6b6b6;
}

.vs-primary-btn {
  border: 1px solid #49c5b1;
  color: #49c5b1;
}

.vs-info-btn {
  border: 1px solid #34657f;
  color: #34657f;
}

.vs-danger-btn {
  border: 1px solid #e1523d;
  color: #e1523d;
}

.vs-warning-btn {
  border: 1px solid #ffb500;
  color: #ffb500;
}

.vs-success-btn {
  border: 1px solid #49c5b1;
  color: #49c5b1;
}

.vs-default-btn,
.vs-primary-btn,
.vs-info-btn,
.vs-danger-btn,
.vs-warning-btn,
.vs-success-btn {
  background: #ffffff;
  box-sizing: border-box;
  outline: none;
}

.vs-solid-primary-btn {
  background: #49c5b1;
  border: 1px solid #49c5b1;
  color: #ffffff;
}

a.vs-btn-d:hover,
.vs-btn-d a:hover {
  text-decoration: none;
}

.vs-default-btn:hover {
  background: rgba(133, 133, 133, 0.1);
  box-shadow: none;
}

.vs-primary-btn:hover {
  background: rgba(0, 175, 102, 0.1);
  box-shadow: none;
}

.vs-info-btn:hover {
  background: rgba(52, 101, 127, 0.1);
  box-shadow: none;
}

.vs-danger-btn:hover {
  background: rgba(221, 56, 52, 0.1);
  box-shadow: none;
}

.vs-warning-btn:hover {
  background: rgba(255, 181, 20, 0.1);
  box-shadow: none;
}

.vs-success-btn:hover {
  background: rgba(73, 197, 177, 0.1);
  box-shadow: none;
}

/* Platform Buttons */

.vs-btn-d svg {
  fill: #adb1b6;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
}

.vs-btn-d:hover svg {
  fill: #49c5b1;
}

.vs-solid-primary-btn {
  background: #49c5b1;
  color: #fff;
}

.vs-solid-primary-btn:hover {
  background: #35b09c;
}

.vs-solid-secondary-btn {
  background: #616c78;
  color: #fff;
}

.vs-solid-secondary-btn:hover {
  background: #48535f;
}

.vs-outline-primary-btn {
  background: transparent;
  border-color: #49c5b1;
  color: #49c5b1;
}

.vs-outline-primary-btn:hover {
  background: rgba(73, 197, 176, 0.1);
  color: #35b09c;
}

.vs-outline-secondary-btn,
.vs-rounded-outline-btn {
  border-color: #adb1b6;
  color: #686f78;
}

.vs-outline-secondary-btn:hover,
.vs-rounded-outline-btn:hover {
  background: rgba(172, 176, 181, 0.1);
  color: #414346;
}

.vs-rounded-solid-btn {
  background: #49c5b1;
  border-radius: 16px;
  color: #fff;
  height: 32px;
  padding: 0;
  width: 32px;
}

.vs-rounded-solid-btn:hover {
  background: #35b09c;
}

.vs-rounded-outline-btn {
  border-radius: 16px;
  height: 32px;
  padding: 0;
  width: 32px;
}

.vs-rounded-outline-btn:hover {
  background: rgba(172, 176, 181, 0.1);
  color: #6f7376;
}

.vs-text-btn {
  background: transparent;
  border-color: transparent;
  color: #6f7376;
  align-items: center;
}

.vs-text-btn:hover {
  background: rgba(111, 115, 118, 0.1);
  color: #414346;
}

.vs-text-btn svg {
  margin-top: -4px;
}

.vs-text-link-btn {
  background-color: transparent;
  color: #148dd1;
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
}

.vs-text-link-btn:hover {
  background: transparent;
  color: #006dac;
}

.vs-icon-btn {
  background: transparent;
  border-color: transparent;
  border-radius: 16px;
  color: #6f7376;
  height: 32px;
  padding: 3px;
  vertical-align: middle;
  width: 32px;
}
.vs-icon-btn:hover {
  background: rgba(111, 115, 118, 0.1);
  color: #414346;
}

/* Disabled state */

.vs-primary-btn.disabled,
.vs-primary-btn[disabled],
.vs-primary-btn.disabled:hover,
.vs-primary-btn[disabled]:hover,
.vs-default-btn.disabled,
.vs-default-btn[disabled],
.vs-default-btn.disabled:hover,
.vs-default-btn[disabled]:hover {
  box-shadow: none;
  opacity: 0.55;
  filter: alpha(opacity=55);
  cursor: not-allowed;
}

.vs-primary-btn.disabled,
.vs-primary-btn[disabled],
.vs-primary-btn.disabled:hover,
.vs-primary-btn[disabled]:hover {
  background: rgba(0, 175, 102, 0.1);
}

.vs-default-btn.disabled,
.vs-default-btn[disabled],
.vs-default-btn.disabled:hover,
.vs-default-btn[disabled]:hover {
  background: rgba(133, 133, 133, 0.1);
}

.vs-btn-d.disabled,
.vs-btn-d[disabled],
.vs-btn-d.disabled:hover,
.vs-btn-d[disabled]:hover {
  border-color: transparent;
  cursor: not-allowed;
}
.vs-solid-primary-btn.disabled,
.vs-solid-primary-btn[disabled],
.vs-solid-primary-btn.disabled:hover,
.vs-solid-primary-btn[disabled]:hover {
  color: #d4efea;
  background: #96d6cc;
}
.vs-solid-secondary-btn.disabled,
.vs-solid-secondary-btn[disabled],
.vs-solid-secondary-btn.disabled:hover,
.vs-solid-secondary-btn[disabled]:hover {
  color: #c0c2c4;
  background: #7f848a;
}
.vs-outline-primary-btn.disabled,
.vs-outline-primary-btn[disabled],
.vs-outline-primary-btn.disabled:hover,
.vs-outline-primary-btn[disabled]:hover {
  background: transparent;
  border-color: #96d6cc;
  color: #afe3db;
}
.vs-outline-secondary-btn.disabled,
.vs-outline-secondary-btn[disabled],
.vs-outline-secondary-btn.disabled:hover,
.vs-outline-secondary-btn[disabled]:hover {
  background: transparent;
  border-color: #c3c8ce;
  color: #b2b9c2;
}
.vs-icon-btn.disabled svg,
.vs-icon-btn[disabled] svg,
.vs-icon-btn.disabled:hover svg,
.vs-icon-btn[disabled]:hover svg {
  fill: #ccd1d7;
}
.vs-icon-btn.disabled,
.vs-icon-btn[disabled],
.vs-icon-btn.disabled:hover,
.vs-icon-btn[disabled]:hover {
  background: transparent;
}
.vs-text-btn.disabled,
.vs-text-btn[disabled],
.vs-text-btn.disabled:hover,
.vs-text-btn[disabled]:hover {
  background: transparent;
  color: #ccd1d7;
}
.vs-text-link-btn.disabled,
.vs-text-link-btn[disabled],
.vs-text-link-btn.disabled:hover,
.vs-text-link-btn[disabled]:hover {
  color: #ccd1d7;
}

/* CJ Button - Inline Button styles*/
.vs-inline-outline-secondary-btn {
  padding: 4px 8px;
  line-height: 24px;
  border-color: #CCCCCC;
  font-weight: 400;
  font-size: 13px;
  text-transform: none;
  border-radius: 4px;
}

.vs-inline-button-icon {
  margin-right: 4px;
  float: left;
}

.vs-inline-button-icon-only {
  margin-right: 0;
}

.vs-inline-button-icon.mdi-icon, .vs-inline-button-icon-only.mdi-icon{
  width: 16px;
}

.vs-inline-button-chevron {
  margin-left: 2px;
  float: right;
}

.vs-inline-button-chevron.mdi-icon {
  width: 22px;
}

.vs-inline-outline-secondary-btn:hover {
  border-color: hsl(0,0%,70%);
  transition: color 150ms;
}

.vs-inline-outline-secondary-btn:hover .mdi-icon{
  fill: hsl(0,0%,60%);
}

/* Button sizing */

.vs-lrg-btn,
.vs-lrg-btn {
  padding: 10px 28px;
  font-size: 1.6rem;
}
