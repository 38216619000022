.vs-card,
.vs-card-clickable {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  color: inherit;
  padding: 20px;
  margin: 10px 20px 10px 0;
  max-width: 100%;
  min-height: 115px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.vs-card-clickable {
  cursor: pointer;
}

.vs-card-clickable:hover {
  text-decoration: none;
  color: #000;
}
