.vs-page-heading {
  background-color: white;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08),
    /*							0 2px 3px rgba(0,0,0,.1),  */ 0 3px 4px -1px rgba(0, 0, 0, 0.08);
  z-index: 2;
}

@media (min-width: 40em) {
  .vs-page-heading--title {
    width: calc(100% - 256px);
  }

  .vs-application-layout-side-collapsed
    + .vs-application-layout-content
    .vs-page-heading--title {
    width: calc(100% - 48px);
  }
}

.vs-page-heading--section {
  right: 1em;
  display: flex;
  position: fixed;
}

.vs-page-heading--section,
.vs-page-heading--title,
.vs-page-heading--section > *,
.vs-page-heading--title > * {
  font-size: 14px;
  font-weight: 400;
}

.vs-page-heading--description {
  border-left: 1px solid rgb(195, 195, 195);
  font-size: 13px;
  font-weight: 400;
  margin-left: 16px;
  padding-left: 16px;
}
