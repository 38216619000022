/** @prettier */
.vs-sidenav {
  background-color: #2a3038;
  font-size: 1.3rem;
  font-weight: 400;
  height: 100%;
  left: 0;
  list-style: none;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 96px 0;
  position: fixed;
  transition: width 0.4s ease-out;
  user-select: none;
  width: 48px;
  z-index: 200;
}

.vs-sidenav ul {
  padding-bottom: 96px;
}

.vs-sidenav h1,
.vs-sidenav ul,
.vs-sidenav li {
  margin: 0;
  padding: 0;
}

.vs-sidenav.active {
  width: 256px;
}

@media (min-width: 40em) {
  .vs-sidenav.collapsed {
    width: 48px;
    transition: 0.4s width ease-out;
  }
  .vs-sidenav.collapsed
    .vs-sidenav-user-menu
    > a
    .vs-sidenav-container-chevron {
    opacity: 0;
  }
}

.vs-sidenav > li ul {
  list-style: none;
}

/* Logo, Branding, App Name */
.vs-sideNav-left-logo {
  background: #49c5b1;
  display: flex;
  height: 48px;
}

.vs-sideNav-left-logo a.vs-sidenav-container-row,
.vs-sideNav-left-logo a.vs-sidenav-container-row:hover {
  display: flex;
  background-color: #49c5b1;
  height: inherit;
  width: 100%;
  justify-content: flex-start;
  padding: 8px;
  border: 0;
}

.vs-sideNav-left-logo a.vs-sidenav-container-row:active svg {
  fill: white;
}

.vs-logo {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
}

.vs-logo .vs-cj-logo {
  fill: white;
  height: 32px;
  width: 32px;
}

.vs-app-name {
  display: inherit;
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
  margin-left: 8px;
  font-family: gibson-light;
  white-space: nowrap;
}

/* SideNav expand and collapse styles */
.vs-sidenav.collapsed .vs-sidenav-container ul li a {
  padding-left: 56px;
  transition: all 0.2s ease-in-out;
}

.vs-sidenav.collapsed .vs-sidenav-link span,
.vs-sidenav.collapsed .vs-sidenav-container-chevron,
.vs-sidenav.collapsed .vs-link-group-children,
.vs-sidenav.collapsed .vs-app-name,
.vs-sidenav.collapsed .vs-sidenav-container-label {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.vs-sidenav.active .vs-sidenav-link span,
.vs-sidenav.active .vs-sidenav-container-chevron,
.vs-sidenav.active .vs-app-name,
.vs-sidenav.active .vs-sidenav-container-label {
  opacity: 1;
  display: inline-block;
  transition: all 0.1s ease-in-out;
}

.vs-sidenav .vs-sidenav-link a:active span {
  color: #ffffff;
}

.vs-sidenav.active .vs-sidenav-link span {
  position: absolute;
}

.vs-sidenav.active .vs-sidenav-link div ~ span {
  margin-top: 3px;
}

.vs-sidenav a {
  align-items: center;
  border-bottom: 1px solid #2a3038;
  color: #bfc3c8;
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 8px 16px 8px 8px;
  text-decoration: none;
  transition: 0.1s all ease-in-out;
}

.vs-sidenav-link-label {
  display: inline;
}

.vs-sidenav .vs-link-group-children a .vs-sidenav-link-label {
  margin-left: 8px;
  width: 184px;
}

.vs-sidenav-container-label {
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 168px;
}

.vs-sidenav-icon {
  font-size: 1.3em;
  text-align: center;
  margin-right: 20px;
  width: 18px;
}

.vs-sidenav-svg-icon {
  align-content: center;
  display: flex;
  height: 32px;
  padding: 7px;
  width: 32px;
}

.vs-placeholder-icon,
.vs-sidenav-svg-icon svg {
  width: 18px;
  height: 18px;
}

.vs-sidenav-svg-icon svg {
  fill: currentColor;
}

.vs-sidenav .vs-link-group-children {
  background-color: #20252b;
}

.vs-sidenav .vs-link-group-children a {
  border-bottom-color: #20252b;
}

.vs-sidenav .vs-link-group-children a:focus,
.vs-sidenav .vs-link-group-children a:hover {
  background: #272c33;
}

.vs-sidenav .vs-sidenav-entry a:focus,
.vs-sidenav .vs-sidenav-entry a:hover,
.vs-sidenav .vs-toggle-icon a:hover {
  background-color: #373d46;
  color: #fff;
}

.vs-sidenav .vs-sidenav-entry a:focus svg,
.vs-sidenav .vs-sidenav-entry a:hover svg {
  fill: #fff;
  transition: 0.1s all ease-in-out;
}

.vs-sidenav a:focus .vs-sidenav-link-label,
.vs-sidenav a:hover .vs-sidenav-link-label {
  color: #fff;
  transition: 0.1s all ease-in-out;
}

.vs-sidenav .vs-sidenav-entry a:focus svg,
.vs-sidenav .expanded > a.vs-sidenav-container-row svg {
  fill: #49c5b1;
  transition: 0.2s all ease-in-out;
}

.vs-sidenav .expanded > a.vs-sidenav-container-row {
  background-color: #373d46;
  color: #fff;
  transition: 0.2s all ease-in-out;
}

.vs-sidenav-link {
  height: 48px;
}

.vs-sidenav .vs-sidenav-container-row {
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.vs-sidenav a.vs-sidenav-container-row {
  cursor: pointer;
}

.vs-sidenav .vs-sidenav-container-row-left {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.vs-sidenav .vs-sidenav-container-chevron {
  transform: rotate(0);
  transition: -webkit-transform 0.2s ease-in-out;
  font-size: 0.8em;
}

.vs-sidenav .expanded .vs-sidenav-container-chevron {
  transform: rotate(90deg);
}

.vs-sidenav .vs-sidenav-container ul {
  max-height: 0;
  overflow: hidden;
  transition: .3s max-height ease-in-out;
  /* transition: 0.4s all cubic-bezier(0.22, 0.61, 0.36, 1); */
  padding: 0;
}

.vs-sidenav .vs-sidenav-container.expanded ul {
  max-height: 960px;
}

.vs-sidenav.collapsed .vs-sidenav-header,
.vs-sidenav .vs-sidenav-header {
  height: 0;
  opacity: 0;
  padding: 0;
  transition: all 0.2s ease-in;
}

@media (min-width: 40em) {
  .vs-sidenav .vs-sidenav-header {
    padding: 10px 20px;
    height: 35px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    transition: all 0.2s ease-in;
    opacity: 1;
  }
}

.vs-single-text {
  margin-top: -17px;
  margin-left: -3px;
  font-size: 12px;
  font-weight: 700;
  color: #adbbc2;
  opacity: 1 !important;
  font-family: sans-serif;
}

.vs-custom {
  font-size: 24px;
  padding-left: -1px;
}

.vs-stacked-icon {
  margin-right: 8px;
}

.vs-sidenav-link-content-wrapper {
  display: flex;
  align-items: center;
}

/* User menu and sidenav toggle */
.vs-sidenav-user-menu,
.vs-toggle-icon {
  background-color: #2a3038;
  position: fixed;
  width: inherit;
}

.vs-sidenav .vs-sidenav-user-menu > a {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  overflow: hidden;
}

.vs-sidenav .vs-sidenav-user-menu a:focus,
.vs-sidenav .vs-sidenav-user-menu a:hover {
  color: #fff;
}

.vs-sidenav-user-menu {
  bottom: 48px;
}

.vs-sidenav .vs-sidenav-user-menu.vs-sidenav-container ul {
  transition: 0.3s max-height ease-in-out;
}

.vs-toggle-icon {
  bottom: 0;
}

.vs-sidenav .vs-sidenav-toggle-icon {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: 8px;
}

.vs-sidenav-toggle-icon .fa {
  font-size: 0.8em;
  margin: 0 auto;
}

.vs-sidenav .vs-sidenav-user-menu a.vs-sidenav-container-row {
  padding: 8px 16px 8px 12px;
}

.vs-sidenav.collapsed .vs-sidenav-user-menu a.vs-sidenav-container-row {
  padding-right: 16px;
}

.vs-sidenav .vs-sidenav-user-menu .vs-sidenav-container-label {
  margin-left: 12px;
}

.vs-user-icon-circle {
  background: #49c5b1;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.vs-user-icon-first,
.vs-user-icon-last {
  font-size: 12px;
  font-weight: 300;
  margin: 3px 0.4px 0;
}

.vs-category-label {
  text-transform: uppercase;
  padding: 22px 16px 8px 16px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  white-space: nowrap;
}
